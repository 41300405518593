import '../styles/custom.css';
import '../styles/team.css';

import { Link } from 'gatsby';
import React, { useEffect } from 'react';
// import smallBlueCircle from '../images/smallCircle.svg';
// import smallRedCircle from '../images/oval-copy-9_2.png';
// import bigOrangeCircle from '../images/oval-copy-10.png';
import Rellax from 'rellax';
import ScrollOut from 'scroll-out';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Amit from '../images/Amit.png';
import Avi from '../images/avigupta.jpg';
import Ashok from '../images/bitmap-copy.png';
import Robert from '../images/bitmap-copy_3.png';
import Hrishikesh from '../images/bitmap-copy-2.png';
import Anil from '../images/bitmap-copy-3.png';
import Correlation from '../images/Correlation.png';
import Eric from '../images/Eric.png';
import GlobeLogo from '../images/globe-logo.png';
import HouseFund from '../images/HouseFund.png';
// import Shridhar from '../images/bitmap-copy_2.png';
import Linkedin from '../images/linked-in.png';
import NorthCoast from '../images/NorthCoast.png';
import Team from '../images/Team.png';
import Tee from '../images/Tee.png';

const OurTeamPage = ( { data } ) => {

  useEffect( () => {
    new Rellax( '.rellax', { // <---- Via class name
      speed: 5
    } );
  }, [] );

  useEffect( () => {
    ScrollOut( {
      threshold: .5,
      once: false,
      cssProps: {
        viewportY: true,
        visibleY: true
      }
    } );
  }, [] );

  return (

    <Layout header={'menu'}>
      <SEO
        title="Meet The Team | Best Insurance Team in California"
        canonicalUrl='https://www.getmybubble.com/our-team/'
        description="Meet the team of get My Bubble. They have vast industry experience; more than 30 years. Get to know about home &amp; life insurance team in California."
        keywords="home insurance agent, life insurance agent, best insurance agents near me, home insurance agents near me"/>

      <script>
        var rellax = new Rellax('.rellax');
        console.log('Hello from rellax')
        ScrollOut();
      </script>

      <div className="flexColumn carrierCommonPage tmview">
        <div className="company-info-container container-fluid">
          <div className="row">
            <div className="col-lg-12 team-heading-container">
              <h1 className="team-heading">Our Team</h1>
            </div>
          </div>
        </div>

        {/* <div className = "container"> */}

        <div className="container-fluid team-container-margin">
          <div className="row">
            {/* <div className="col-lg-2"></div>  */}

            <div className="col-12">
              <h3 className="team-container-2-heading align-team-text-mv">
                We are passionate about building simple, intuitive and lasting solutions to real world problems.
              </h3>

              <p className="our-team-para align-team-text-mv">
                Bubble's founding team has decades of experience in technology, data science, AI, economics, and innovation. They have developed path-breaking solutions to help consumers, professionals, and businesses in domains as diverse as real estate and finance, digital and social marketing, electronic and chip design, recruiting and hiring, and insurance and financial services.
              </p>

              {/* <p className="our-team-para align-team-text-mv">
                And along the way, we strive to bring smiles to people's faces... and peace &amp; warmth to their hearts!
              </p> */}
            </div>

            {/* <div className="col-lg-2"></div> */}
          </div>
        </div>


        {/* <div className="container common-container-space">
          <h3 className="align-center heading-style">Leadership</h3>
          Desktop View
          <div className="row h-100  our-team-desktop-view" data-scroll>
            <div className="col-lg-6 col-12 content team-custom-style">
              <div className="flexwrap">
                <h3 className="name-font-style">Avi Gupta</h3>

                <u className="underline-color"><div className="position-font-style display-content">Founder &amp; CEO</div></u>

                <div className="description-font-style display-content">Serial tech entrepreneur with passion for using data, AI &amp;
                  software to simplify lives &amp; delight users.
                </div>

                <div className="mb-3 location-font-style display-content">MS - UC Berkeley. Ph.D. Computer Science - Michigan Ann Arbor</div>

                <a href=" https://www.linkedin.com/in/avigupta/" target="_blank"><img src={Linkedin} alt="linkedin"/></a>
              </div>
            </div>

            <div className="col-lg-6 d-flex flexCenter title desk-view">
              <div className="profile-bg pb-5">
                <img src={Avi} alt="avi"/>
              </div>

              <img src={smallBlueCircle} alt="Bubble Life Insurance" className="rellax smallBlueCircle1" data-rellax-speed="2" />

              <img src={smallRedCircle} alt="Bubble Life Insurance" className="rellax smallRedCircle1" data-rellax-speed="2" />

              <img src={bigOrangeCircle} alt="Bubble Life Insurance" className="rellax bigOrangeCircle1" data-rellax-speed="2" />
            </div>
          </div>
          Mobile view
          <div className="row h-100  our-team-mobile-view">
            <div className="col-lg-6 d-flex flexCenter title desk-view">
              <div className="profile-bg pb-5">
                <img src={Avi} alt="Avi"></img>
              </div>

              <img src={smallBlueCircle} alt="Bubble Life Insurance" className="rellax smallBlueCircle1" data-rellax-speed="2" />

              <img src={smallRedCircle} alt="Bubble Life Insurance" className="rellax smallRedCircle1" data-rellax-speed="2" />

              <img src={bigOrangeCircle} alt="Bubble Life Insurance" className="rellax bigOrangeCircle1" data-rellax-speed="2" />
            </div>

            <div className="col-lg-6 col-12 content team-custom-style">
              <div className="flexwrap">
                <h3 className="name-font-style">Avi Gupta</h3>

                <u className="underline-color"><div className="position-font-style display-content">Founder &amp; CEO</div></u>

                <div className="description-font-style display-content">Serial tech entrepreneur with passion for using data, AI &amp;
                  software to simplify lives &amp; delight users.
                </div>

                <div className="mb-3 location-font-style display-content">MS - UC Berkeley. Ph.D. Computer Science - Michigan Ann Arbor</div>

                <a href=" https://www.linkedin.com/in/avigupta/" target="_blank"><img src={Linkedin} className="pb-5" alt="linkedin" /></a>

              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="container common-container-space">
          <div className="row h-100 align-img-mv">
            <div className="col-lg-6 d-flex flexCenter title desk-view" data-scroll>
              <div className="profile-bg pb-5">
                <img src={Ashok} alt="ashok"/>
              </div>

              <img src={smallBlueCircle} alt="Bubble Life Insurance" className="rellax smallBlueCircle2" data-rellax-speed="2" />

              <img src={smallRedCircle} alt="Bubble Life Insurance" className="rellax smallRedCircle2" data-rellax-speed="2" />

              <img src={bigOrangeCircle} alt="Bubble Life Insurance" className="rellax bigOrangeCircle2" data-rellax-speed="2" />
            </div>

            <div className="col-lg-6 col-12 content team-custom-style">
              <div className="flexwrap rightcontent">
                <h3 className="name-font-style">Ashok Bardhan</h3>

                <u className="underline-color"><div className="position-font-style display-content">Founder &amp; Chief Analytics Officer</div></u>

                <div className="description-font-style display-content">Economist &amp; data scientist. Expertise in financial services, technology &amp; public policy.</div>

                <div className="mb-3 location-font-style display-content">Ph.D. Economics - UC Berkeley</div>

                <a href=" https://www.linkedin.com/in/ashok-bardhan-18a08392/" target="_blank"><img src={Linkedin} className="pb-5" alt="linkedin" /></a>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="container common-container-space">
          Desktop view
          <div className="row h-100 our-team-desktop-view">
            <div className="col-lg-6 col-12 content team-custom-style">
              <div className="flexwrap rightcontent">
                <h3 className="name-font-style">Hrishikesh Kunte</h3>

                <u className="underline-color"><div className="position-font-style display-content">Product Leader</div></u>

                <div className="description-font-style display-content">Product &amp; growth hacker across Insurtech, Foodtech, e-commerce &amp; Classifieds.</div>

                <div className="mb-3 location-font-style display-content">BS Mechanical, MBA UC Berkeley</div>

                <a href="https://in.linkedin.com/in/hrishikesh-kunte-chief-product-officer-product-manager-a0487b13" target="_blank" rel="noreferrer"><img src={Linkedin} className="pb-5" alt="linkedin" /></a>
              </div>
            </div>

            <div className="col-lg-6 d-flex flexCenter title desk-view" data-scroll>
              <div className="profile-bg pb-5">
                <img src={Hrishikesh} alt="hrishikesh"/>
              </div>

              <img src={smallBlueCircle} alt="Bubble Life Insurance" className="rellax smallBlueCircle3" data-rellax-speed="2" />

              <img src={smallRedCircle} alt="Bubble Life Insurance" className="rellax smallRedCircle3" data-rellax-speed="2" />

              <img src={bigOrangeCircle} alt="Bubble Life Insurance" className="rellax bigOrangeCircle3" data-rellax-speed="2" />
            </div>
          </div>

          Mobile View
          <div className="row h-100 our-team-mobile-view">
            <div className="col-lg-6 d-flex flexCenter title desk-view">
              <div className="profile-bg pb-5">
                <img src={Hrishikesh} alt="hrishikesh"/>
              </div>

              <img src={smallBlueCircle} alt="Bubble Life Insurance" className="rellax smallBlueCircle3" data-rellax-speed="2" />

              <img src={smallRedCircle} alt="Bubble Life Insurance" className="rellax smallRedCircle3" data-rellax-speed="2" />

              <img src={bigOrangeCircle} alt="Bubble Life Insurance" className="rellax bigOrangeCircle3" data-rellax-speed="2" />
            </div>

            <div className="col-lg-6 col-12 content team-custom-style">
              <div className="flexwrap rightcontent">
                <h3 className="name-font-style">Hrishikesh Kunte</h3>

                <u className="underline-color"><div className="position-font-style display-content">Product Leader</div></u>

                <div className="description-font-style display-content">Product &amp; growth hacker across Insurtech, Foodtech, e-commerce &amp; Classifieds.</div>

                <div className="mb-3 location-font-style display-content">BS Mechanical, MBA UC Berkeley</div>

                <a href="https://in.linkedin.com/in/hrishikesh-kunte-chief-product-officer-product-manager-a0487b13" target="_blank" rel="noreferrer"><img src={Linkedin} className="pb-5" alt="linkedin" /></a>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="container common-container-space">
          <div className="row h-100 align-img-mv">
            <div className="col-lg-6 d-flex flexCenter title desk-view" data-scroll>
              <div className="profile-bg pb-5">
                <img src={Shridhar} alt="shridhar"/>
              </div>

              <img src={smallBlueCircle} alt="Bubble Life Insurance" className="rellax smallBlueCircle4" data-rellax-speed="2" />

              <img src={smallRedCircle} alt="Bubble Life Insurance" className="rellax smallRedCircle4" data-rellax-speed="2" />

              <img src={bigOrangeCircle} alt="Bubble Life Insurance" className="rellax bigOrangeCircle4" data-rellax-speed="2" />
            </div>

            <div className="col-lg-6 col-12 content team-custom-style">
              <div className="flexwrap rightcontent">
                <h3 className="name-font-style">Shridhar Dhaiphule</h3>

                <u className="underline-color"><div className="position-font-style display-content">Sr. Director Engineering</div></u>

                <div className="description-font-style display-content">Technology geek. Solution architect. Engineering leader.</div>

                <div className="mb-3 location-font-style display-content">B.E. Electronics. eMBA - I.I.M. India</div>

                <a href="https://www.linkedin.com/in/shridhardhaiphule/" target="_blank" rel="noreferrer"><img src={Linkedin} className="pb-5" alt="linkedin"/></a>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="container common-container-space">
          Desktop view
          <div className="row h-100 our-team-desktop-view">
            <div className="col-lg-6 col-12 content team-custom-style">
              <div className="flexwrap rightcontent">
                <h3 className="name-font-style">Anil Chitale</h3>

                <u className="underline-color"><div className="position-font-style display-content">Industry Consultant</div></u>

                <div className="description-font-style display-content">30-year insurance industry veteran and technology entrepreneur, ex-founder of STG &amp; SVP Majesco.</div>

                <a href="https://www.linkedin.com/in/anilchitale-cpcu-arm/" target="_blank" rel="noreferrer"><img src={Linkedin} className="pb-5" alt="linkedin" /></a>
              </div>
            </div>

            <div className="col-lg-6 d-flex flexCenter title desk-view" data-scroll>
              <div className="profile-bg pb-5">
                <img src={Anil} alt="Anil"/>
              </div>

              <img src={smallBlueCircle} alt="Bubble Life Insurance" className="rellax smallBlueCircle5" data-rellax-speed="2" />

              <img src={smallRedCircle} alt="Bubble Life Insurance" className="rellax smallRedCircle5" data-rellax-speed="2" />

              <img src={bigOrangeCircle} alt="Bubble Life Insurance" className="rellax bigOrangeCircle5" data-rellax-speed="2" />
            </div>
          </div>

          Mobile View
          <div className="row h-100 our-team-mobile-view">
            <div className="col-lg-6 d-flex flexCenter title desk-view">
              <div className="profile-bg pb-5">
                <img src={Anil} alt="Anil"/>
              </div>

              <img src={smallBlueCircle} alt="Bubble Life Insurance" className="rellax smallBlueCircle5" data-rellax-speed="2" />

              <img src={smallRedCircle} alt="Bubble Life Insurance" className="rellax smallRedCircle5" data-rellax-speed="2" />

              <img src={bigOrangeCircle} alt="Bubble Life Insurance" className="rellax bigOrangeCircle5" data-rellax-speed="2" />
            </div>

            <div className="col-lg-6 col-12 content team-custom-style">
              <div className="flexwrap rightcontent">
                <h3 className="name-font-style">Anil Chitale</h3>

                <u className="underline-color"><div className="position-font-style display-content">Industry Consultant</div></u>

                <div className="description-font-style display-content">30-year insurance industry veteran and technology entrepreneur, ex-founder of STG &amp; SVP Majesco.</div>

                <a href="https://www.linkedin.com/in/anilchitale-cpcu-arm/" target="_blank" rel="noreferrer"><img src={Linkedin} className="pb-5" alt="linkedin" /></a>
              </div>
            </div>
          </div>

        </div> */}

        {/* <div className="container common-container-space mgb-68">
          <div className="row h-100 align-img-mv">
            <div className="col-lg-6 d-flex flexCenter title desk-view" data-scroll>
              <div className="profile-bg pb-5">
                <img src={Robert} alt="Robert"/>
              </div>

              <img src={smallBlueCircle} alt="Bubble Life Insurance" className="rellax smallBlueCircle6" data-rellax-speed="2" />

              <img src={smallRedCircle} alt="Bubble Life Insurance" className="rellax smallRedCircle6" data-rellax-speed="2" />

              <img src={bigOrangeCircle} alt="Bubble Life Insurance" className="rellax bigOrangeCircle6" data-rellax-speed="2" />
            </div>

            <div className="col-lg-6 col-12 content team-custom-style">
              <div className="flexwrap rightcontent">
                <h3 className="name-font-style">Robert H. Edelstein</h3>

                <u className="underline-color"><div className="position-font-style display-content">Faculty Profile</div></u>

                <div className="description-font-style display-content">Prof. Emeritus, Haas School of Business, UC Berkeley; Co-Chair, Fisher Center for Real Estate &amp; Urban Economics.</div>

                <div className="mb-3 location-font-style display-content">Advisor</div>

                <div id="comp-kf702764" className="_2bafp" data-testid="richTextElement">
                  <p className="font_8" style={{ fontSize: '15px', lineHeight: '1.4em', textAlign: 'center' }}></p>
                </div>

                <a href="https://haas.berkeley.edu/faculty/edelstein-robert/" target="_blank" rel="noreferrer"><img src={GlobeLogo} className="pb-5" alt="logo"/></a>
              </div>
            </div>
          </div>
        </div> */}

        {/* </div> */}


        {/* Leadership Section Start */}

        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="align-center heading-style mb-0">Leadership</h3>
            </div>

            <div className="col-lg-4 col-md-6 col-12 ">
              <div className="TeamLeaders">
                <img src={Avi}  className="w-100 bradius" alt="Avi Gupta"></img>

                <div className="TeamLeadersDetails">
                  <h4>Avi Gupta</h4>

                  <span>Founder &amp; CEO</span>
                </div>

                <div className="LeadersDetailsBoxHover">
                  <h4>Avi Gupta</h4>

                  <span>Founder &amp; CEO</span>

                  <p>Avi’s got the entrepreneurial bug – and with each startup, his vision gets bigger and bolder. He founded SmartZip, which pioneered the use of predictive analytics and data-driven marketing automation for real estate – its products powered many popular home search portals and helped brokers, lenders, and agents more efficiently grow their business. Avi is always up for a game of tennis, table tennis, pickleball or golf, dabbles in drums and the tabla, loves to bike and can be coerced to run.</p>

                  <a href=" https://www.linkedin.com/in/avigupta/" target="_blank"><img src={Linkedin} alt="LinkedIn"></img></a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 ">
              <div className="TeamLeaders">
                <img src={Ashok}  className="w-100" alt="Ashok Bardhan"></img>

                <div className="TeamLeadersDetails">
                  <h4>Ashok Bardhan</h4>

                  <span>Co-founder &amp; Chief Analytics Officer</span>
                </div>

                <div className="LeadersDetailsBoxHover">
                  <h4>Ashok Bardhan</h4>

                  <span>Co-founder &amp; Chief Analytics Officer</span>

                  <p>An Economist and Data Scientist, Ashok would like to bring the science, sense and sensibility of economics and other social sciences to the practice of AI/Machine Learning for solving real-world problems. He has an MS in Physics and Mathematics, an MPhil in International Relations, and a PhD in Economics. Ashok finds cooking and biking relaxing, music inspiring, mystery novels thrilling, and learning new languages both challenging and rewarding. </p>

                  <a href="https://www.linkedin.com/in/ashok-bardhan-18a08392/" target="_blank" rel="noreferrer"><img alt="LinkedIn" src={Linkedin}></img></a>
                </div>

              </div>
            </div>


            <div className="col-lg-4 col-md-6 col-12 ">
              <div className="TeamLeaders">
                <img src={Hrishikesh} alt="Hrishikesh Kunte" className="w-100"></img>

                <div className="TeamLeadersDetails">
                  <h4>Hrishikesh Kunte</h4>

                  <span>VP Product</span>
                </div>

                <div className="LeadersDetailsBoxHover">
                  <h4>Hrishikesh Kunte</h4>

                  <span>VP Product</span>

                  <p>Hrishikesh is an accomplished product leader and entrepreneur with diverse experience in building and growing products for global markets. He has a unique blend of Product, Marketing and Business. With his experience in classifieds, foodtech, healthtech, and insurtech, he brings the expertise of building innovative products that solve customers’ needs. Outside of work, he enjoys playing squash and most importantly spending time with his family. Obsessed with all things Steve Jobs and Nike, he goes by the “Keep it simple” mantra.</p>

                  <a href="https://in.linkedin.com/in/hrishikesh-kunte-chief-product-officer-product-manager-a0487b13" target="_blank" rel="noreferrer">
                    <img src= {Linkedin} alt="LinkedIn"/>
                  </a>
                </div>

              </div>
            </div>


            {/* <div className="col-lg-4 col-md-6 col-12 ">
              <div className="TeamLeaders">
                <img src={Eric} alt="Eric"  className="w-100"></img>

                <div className="TeamLeadersDetails">
                  <h4>Eric Horrocks</h4>

                  <span>Director of Business Development</span>
                </div>

                <div className="LeadersDetailsBoxHover">
                  <h4>Eric Horrocks</h4>

                  <span>Director of Business Development</span>

                  <p>With 10 years of industry experience under his belt, Eric’s main purpose in Bubble is to develop and maintain an environment where the customer is the #1 priority. When he’s not focused on expanding the Bubble family, he dreams of vacationing in Bora Bora, learning how to sail and skydive, and visiting all 7 wonders of the world! Now that’s a fun bucket list.</p>

                  <a href="https://www.linkedin.com/in/ericjhorrocks/" target="_blank" rel="noreferrer">
                    <img src={Linkedin} alt="LinkedIn"/></a>
                </div>

              </div>
            </div> */}


            <div className="col-lg-4 col-md-6 col-12 ">
              <div className="TeamLeaders">
                <img src={Amit} alt="Amit Vyas" className="w-100"></img>

                <div className="TeamLeadersDetails">
                  <h4>Amit</h4>

                  <span> Director of Engineering</span>
                </div>

                <div className="LeadersDetailsBoxHover">
                  <h4>Amit</h4>

                  <span> Director of Engineering</span>

                  <p>With 15 years of experience under his belt, Amit has worked in sectors like education, computer games, communications, entertainment, and fashion tech. After getting his masters degree from BITS Pilani, Amit trained in scientific visualisation at NASA and worked with Conexant, Novell, and the likes. He enjoys being a polyglot engineer who loves contributing to what Bubble does–helping people make the most important decision of their lives, home & life insurance. Besides work, he enjoys reading technical literature, playing racquet games, and mobile games. He loves working on solutions that make the world a better place.
                  </p>

                  <a href="https://www.linkedin.com/in/amitvyas1/" target="_blank" rel="noreferrer"><img src={Linkedin} alt="LinkedIn"></img></a>
                </div>

              </div>
            </div>


            <div className="col-lg-4 col-md-6 col-12 ">
              <div className="TeamLeaders">
                <img src={Anil} alt="Anil Chitale" className="w-100"></img>

                <div className="TeamLeadersDetails">
                  <h4>Anil Chitale</h4>

                  <span>InsurTech Consultant</span>
                </div>

                <div className="LeadersDetailsBoxHover">
                  <h4>Anil Chitale</h4>

                  <span>InsurTech Consultant</span>

                  <p>Creating something out of nothing and seeing opportunities that seem like hopeless romances to many, excites him the most. Over the past 3 decades, he has partnered with first-generation and serial entrepreneurs helping them successfully launch, build, and grow businesses from concept to multi-million dollar viable entities in the US P&C insurance industry. Anil keeps his sanity with hiking, swimming, yoga, gourmet dining, wine tasting, drives with his family, and long walks with family dog Sweetie!

                  </p>

                  <a href="https://www.linkedin.com/in/anilchitale-cpcu-arm/" target="_blank" rel="noreferrer"><img alt="LinkedIn" src={Linkedin}></img></a>
                </div>

              </div>
            </div>


            {/* <div className="col-12 mt-5 pt-lg-5 text-center">
              <h3 className="align-center heading-style mb-0">Advisors</h3>
            </div> */}

            <div className="col-lg-4 col-md-6 col-12 mx-auto">
              <div className="TeamLeaders">
                <img src={Robert} alt="Robert Edelstein" className="w-100"></img>

                <div className="TeamLeadersDetails">
                  <h4>Robert H. Edelstein</h4>

                  <span>Housing Industry Advisor</span>
                </div>

                <div className="LeadersDetailsBoxHover">
                  <h4>Robert H. Edelstein</h4>

                  <span>Housing Industry Advisor</span>

                  <p>Robert Edelstein holds the Maurice Mann Chair in Real Estate and is the co-director of the Fisher Center for Real Estate and Urban Development at Berkeley Haas. His expertise lies in urban real estate, urban financial problems, and property taxation among a variety of other subjects. Robert has authored the book, “Explaining the Boom Cycle, Speculation or Fundamentals? The Role of Real Estate in the Asian Crisis."</p>

                  <a href="https://haas.berkeley.edu/faculty/edelstein-robert/" target="_blank" rel="noreferrer">
                    <img src={GlobeLogo} alt="Website"/>
                  </a>
                </div>

              </div>
            </div>


          </div>
        </div>

        <div className="container mb-lg-0 mb-5">
          <div className="row">
            <div className="col-12 mt-5 col-lg-8 mx-auto text-center">
              <h3 className="align-center heading-style mb-0">Investors</h3>

              <p className="text-center">Bubble's founding team has decades of technology experience innovating solutions to help consumers, professionals and businesses.</p>

              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mx-auto mt-3">
                  <img src={HouseFund} alt="HouseFund" className="w-100"></img>
                </div>

                <div className="col-12 col-md-4 col-lg-4 mx-auto mt-3">
                  <img src={Tee} alt="Tee"  className="w-100"></img>
                </div>

                <div className="col-12 col-md-4 col-lg-4 mx-auto mt-3">
                  <img src={Correlation} alt="Correlation Ventures" className="w-100"></img>
                </div>

                <div className="col-12 col-md-2 col-lg-2 mx-auto ">
                </div>

                <div className="col-12 col-md-4 col-lg-4 mx-auto mt-3">
                  <img src={NorthCoast}  alt="NorthCoast" className="w-100"></img>
                </div>

                <div className="col-12 col-md-2 col-lg-2 mx-auto ">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="plansForYou">
          <div className="orangeBackGround">
            <div>
          Find out which plan is right for you
            </div>

            <div className="customizedPlan">
          Policies are customized according to your needs.
          We have simplified the process to help you find the right policy that fits your budget and loved ones.
            </div>

            <Link to="/" type="button" className="btnWhite">
          Get your quote now
            </Link>
          </div>
        </div>


      </div>
    </Layout>


  );


};

export default OurTeamPage;
